// Load TailwindCSS
import './styles/app.css';

import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import App from './App.vue'

const routes = [];
const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
});

const app = createApp(App);
app.use(router);
app.mount('#app');
