<template>
<div class="mb-4 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
  <div class="flex px-4 py-5 sm:px-6 justify-center">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Welcome{{ username }} 👋
    </h3>
  </div>
</div>
</template>

<script>
export default {
  name: 'User',
  data() {
    return {
      'username': "",
    }
  },
  props: [
      'user'
  ],
  async mounted() {
    this.username = "";
  },
  watch: {
    async user() {
      let username = this.user.username;
      if (username === undefined) {
        username = "";
      } else {
        username = ` ${username}`;
      }
      this.username = username;
    }
  }
}
</script>
