<template>
<div class="max-w-2xl mx-auto">
  <User :user="user" />
  <PayPal :user="user" v-if="isAuthenticated" />
  <Discord v-else />
</div>
</template>

<script>
import Discord from './components/Discord.vue'
import PayPal from './components/PayPal.vue'
import User from './components/User.vue';
import ls from "localstorage-slim";

async function getUser(accessToken) {
  // return {
  //   token: accessToken,
  //   id: "771380104159952927",
  //   username: "micr0#0001",
  //   avatar: "https://cdn.discordapp.com/avatars/771380104159952927/c42ee045fffbf7aa77d9f2c2f102eed5.png",
  // };

  const discordResponse = await fetch(`${process.env.VUE_APP_BASE_URL}/discord/user/${btoa(accessToken)}`);

  return await discordResponse.json();
}

export default {
  name: 'App',
  components: {
    Discord,
    PayPal,
    User
  },
  data() {
    return {
      user: {},
      isAuthenticated: false
    }
  },
  async mounted () {
    const auth = ls.get("auth");
    if (auth !== null && auth.a !== undefined && auth.a !== "") {
      this.user = await getUser(auth.a);
      if (this.user.id !== undefined && this.user.id > 1) {
        this.isAuthenticated = true;
      }
    }
  },
  computed: {
    queryToken() {
      return this.$route.query.token;
    }
  },
  watch: {
    async queryToken(newToken) {
      if (newToken !== undefined && newToken !== "") {
        const token = atob(newToken).split('|');
        ls.set("auth", {"token": this.$route.query.token,"a":token[0],"u":token[1], "s":Math.round(Math.random()*5)}, {"ttl":parseInt(token[3])});
        window.location = process.env.VUE_APP_BASE_URL;
      }

      const auth = ls.get("auth");
      if (auth !== null && auth.a !== undefined && auth.a !== "") {
        this.user = await getUser(auth.a);
        if (this.user.id !== undefined && this.user.id > 1) {
          this.isAuthenticated = true;
        }
      }
    }
  }
}
</script>
