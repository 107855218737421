<template>
<div class="mb-4 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Information
    </h3>
  </div>
  <div class="px-4 py-5 sm:p-6">
    <div>
      <p>You can get a link to <span class="font-medium text-gray-900">add {{ link_time }}</span> to the session.</p>
      <p>Each link costs <span class="font-medium text-gray-900">{{ link_price }} &euro;</span> and is <span class="font-medium text-gray-900">valid {{ link_valid_until }}</span>.</p>
    </div>
  </div>
</div>

<div v-if="hasLink" class="mb-4 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Your Link
    </h3>
  </div>
  <div class="px-4 py-5 sm:p-6">
    <div>
      <div class="flex">
        <a :href="link" class="grow p-2 w-full bg-green-600 rounded shadow-lg sm:p-3 truncate" target="_blank">
          <p class="text-center font-medium text-white truncate">{{ link }}</p>
        </a>
        <div class="ml-2 flex grow-0 cursor-pointer">
          <div v-if="copy" @click="copyLink" class="p-2 bg-green-600 rounded shadow-lg sm:p-3">
            <ClipboardCheckIcon class="h-6 w-6 font-medium text-white"/>
          </div>
          <div v-else @click="copyLink" class="p-2 bg-gray-600 rounded shadow-lg sm:p-3">
            <ClipboardCopyIcon class="h-6 w-6 font-medium text-white"/>
          </div>
        </div>
      </div>
      <p class="mt-2 text-xs text-center">The link will disappear when a new link order is started or the website is reloaded.</p>
    </div>
  </div>
</div>

<div v-if="orderError" class="mb-4 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Your Link
    </h3>
  </div>
  <div class="px-4 py-5 sm:p-6">
    <div>
      <div class="p-2 rounded-lg bg-red-600 shadow-lg sm:p-3">
        <p class="m-auto text-white">I couldn't generate a link for you 😢</p>
      </div>
      <div class="mt-4">
        <p class="font-medium">Use the following information to request a refund &sup1;</p>
        <ul role="list" class="divide-y divide-gray-200">
          <li>
            <div class="flex px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-auto flex">
                <p class="text-sm font-medium text-indigo-600">UserID</p>
              </div>
              <div class="flex-auto flex">
                <p class="text-sm">{{ user.id }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-auto flex">
                <p class="text-sm font-medium text-indigo-600">OrderID</p>
              </div>
              <div class="flex-auto flex">
                <p class="text-sm">{{ orderId }}</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-auto flex">
                <p class="text-sm font-medium text-indigo-600">TransactionID</p>
              </div>
              <div class="flex-auto flex">
                <p class="text-sm italic">The Transaction-ID from your payment.<br />(You can find it in your PayPal)</p>
              </div>
            </div>
          </li>
        </ul>
        <p class="text-xs italic">&sup1; Without all of these information the refund can't be processed.</p>
        <p class="mt-4 text-xs italic">This message will disappear when a new link order is started or the website is reloaded.</p>
      </div>
    </div>
  </div>
</div>

<div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Buy a link
    </h3>
  </div>
  <div class="px-4 py-5 sm:p-6">
    <div id="paypal-button-container"></div>
  </div>
</div>
</template>

<script>
import { loadScript } from '@paypal/paypal-js';
import { calculateTime } from "@/services/time";
import { copyText } from 'vue3-clipboard';
import { ClipboardCheckIcon, ClipboardCopyIcon } from '@heroicons/vue/solid';

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function getLinkValidTime() {
  if (process.env.VUE_APP_LINK_VALID_UNITL !== undefined && process.env.VUE_APP_LINK_VALID_UNITL != 0) {
    return `for ${process.env.VUE_APP_LINK_VALID_UNITL/60} minutes`;
  }

  return "until the session ends";
}

export default {
  name: 'PayPal',
  props: [
      'user'
  ],
  components: {
    ClipboardCheckIcon,
    ClipboardCopyIcon,
  },
  data() {
    return {
      link_time: calculateTime(process.env.VUE_APP_LINK_DURATION),
      link_price: process.env.VUE_APP_LINK_PRICE,
      link_valid_until: getLinkValidTime(),
      link: "",
      orderId: "",
      orderError: false,
      hasLink: false,
      copy: false,
    }
  },
  async mounted() {
    if (this.user.id !== undefined && this.user.id !== 0) {
      await this.renderPayPal();
    }
  },
  watch: {
    async user() {
      if (this.user.id !== undefined && this.user.id !== 0) {
        await this.renderPayPal();
      }
    },
    link(newLink) {
      if (newLink !== "" && this.orderError === false) {
        this.hasLink = true;
      }
    }
  },
  methods: {
    renderPayPal: async function () {
      const paypalSdk = await loadScript({
        'client-id': process.env.VUE_APP_PAYPAL_CLIENT_ID,
        currency: 'EUR',
      });
      await paypalSdk.Buttons({
        createOrder: this.paypalCreateOrder,
        onApprove: this.paypalApprove
      }).render('#paypal-button-container');
    },
    paypalCreateOrder: function (data, actions) {
      this.hasLink = false;
      this.orderError = false;
      return actions.order.create({
        application_context: {
          brand_name: "micr0.net",
          shipping_preference: "NO_SHIPPING"
        },
        purchase_units: [{
          custom_id: this.user.id,
          items: [{
            name: 'Instant Link',
            unit_amount: {
              value: process.env.VUE_APP_LINK_PRICE,
              currency_code: 'EUR'
            },
            quantity: 1
          }],
          amount: {
            value: process.env.VUE_APP_LINK_PRICE,
            currency_code: 'EUR',
            breakdown: {
              item_total: {
                value: process.env.VUE_APP_LINK_PRICE,
                currency_code: 'EUR'
              }
            }
          }
        }]
      });
    },
    paypalApprove: function (data, actions) {
      // This function captures the funds from the transaction.
      return actions.order.capture().then(async (details) => {
        // This function shows a transaction success message to your buyer.
        // console.log("Transaction completed", details);

        // const linkJson = {
        //   details: details,
        //   linkId: "zavsxn5ouf",
        //   link: "https://www.emlalock.com/#/i/zavsxn5ouf"
        // };

        const linkResponse = await fetch(`${process.env.VUE_APP_BASE_URL}/link/${details.id}`);
        const linkJson = await linkResponse.json();

        this.orderError = linkJson.linkId === "undefined";
        this.orderId = details.id;
        this.link = linkJson.link;
      });
    },
    copyLink: async function () {
      copyText(this.link, undefined, async (error) => {
        if (error) {
          console.log(error);
        } else {
          this.copy = true;
          await delay(1000);
          this.copy = false;
        }
      })
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active { transition: opacity 0.5s ease; }
.fade-enter-from, .fade-leave-to { opacity: 0; }
</style>
