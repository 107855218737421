export function calculateTime(time) {
    const days = Math.floor(time / 86400);
    const hours = Math.floor((time % 86400) / 3600);
    const minutes = Math.floor(((time % 86400) % 3600) / 60);
    const seconds = ((time % 86400) % 3600) % 60;
    let string = "";
    if (days > 0 && days <= 1) { string = `${string} ${days} day`; }
    if (days > 1) { string = `${string} ${days} days`; }
    if (string.length > 0 && hours > 0) { string = `${string},`; }
    if (hours > 0 && hours <= 1) { string = `${string} ${hours} hour`; }
    if (hours > 1) { string = `${string} ${hours} hours`; }
    if (string.length > 0 && minutes > 0) { string = `${string},`; }
    if (minutes > 0 && minutes <= 1) { string = `${string} ${minutes} minute`; }
    if (minutes > 1) { string = `${string} ${minutes} minutes`; }
    if (string.length > 0 && seconds > 0) { string = `${string},`; }
    if (seconds > 0 && seconds <= 1) { string = `${string} ${seconds} second`; }
    if (seconds > 1) { string = `${string} ${seconds} seconds`; }

    return string.trim();
}
